.ant-tabs-tab {
  flex: 1;
  font-family: Montserrat;
  color: #6c7b8a;
}

.ant-tabs-tab + div.ant-tabs-tab {
  margin: 0;
}

.ant-tabs-tab > div {
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn[id$='tab-1'] {
  color: #26ccdd;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn[id$='tab-2'] {
  color: #ee8183;
}

.ant-tabs-nav-list {
  width: 100%;
}

div.ant-tabs-ink-bar {
  width: 50%;
}

.ant-tabs-tab-active ~ div.ant-tabs-ink-bar {
  width: 50%;
  background-color: #26ccdd;
}
.ant-tabs-tab-active + div.ant-tabs-ink-bar {
  width: 50%;
  background-color: #ee8183;
}
